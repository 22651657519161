import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Banner from "../components/Banner"

import SEO from "../components/SEO"

const errorPage = () => {
  return (
    <Layout>
      <SEO title="404" keywords={[``]} />
      <Header>
        <Banner title="404" info="Ooops wrong way" error>
          <AniLink fade to="/" className="btn-white">
            Back to home
          </AniLink>
        </Banner>
      </Header>
    </Layout>
  )
}

const Header = styled.div`
  background: var(--primaryColor);
  min-height: calc(100vh - 62px);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default errorPage
